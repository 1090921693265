@use 'common'

@font-face
	font-family: 'republic'
	src: url('/fonts/republic_regular_std-webfont.woff2') format('woff2'), url('/fonts/republic_regular_std-webfont.woff') format('woff')
	font-weight: normal
	font-style: normal

@font-face
	font-family: 'republic'
	src: url('/fonts/republic_bold_std-webfont.woff2') format('woff2'), url('/fonts/republic_bold_std-webfont.woff') format('woff')
	font-weight: 700
	font-style: normal

@font-face
	font-family: 'despekt'
	src: url('/fonts/despekt2012_0_1-webfont.woff2') format('woff2'), url('/fonts/despekt2012_0_1-webfont.woff') format('woff')
	font-weight: normal
	font-style: normal

html,
body
	color: #000
	padding: 0
	margin: 0
	scroll-behavior: smooth
	font-family: despekt, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif

a
	color: inherit
	text-decoration: none

*
	box-sizing: border-box
